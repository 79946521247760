import React from 'react';
import userConfig from '../../../config';

import Container from '../Container';
import HeaderImage from '../HeaderImage';
import Social from '../Social';
import H1 from '../H1';
import P from './P';
import Link from './Link';
import Wrapper from './Wrapper';

function Header({ config }) {
  const { social } = config;

  return (
    <Container>
      <Wrapper>
        {userConfig.showHeaderImage && (
          <HeaderImage/>
        )}
        <H1><Link to="https://voyeurhouse.com/live">Watch Voyeur Videos and Hotscopes 24/7</Link></H1>
        <Link to="https://voyeurhouse.com"><P>One of the ambitious pornographic projects of all time, Voyeur House lets you watch amateur porn. Live. With real people. You know what, just to make it easier for you to understand, imagine Voyeur House as Hotscope meets Big Brother meets high production values and lots and lots of hot people. The premise of this live sex website is simple if you really think about it. There are over 30 apartments riddled with cameras (those are NOT spy sex cams because the participants are well aware of them). Thanks to the meticulous of the surveillance devices, there's not a square inch of any given apartment that is not covered by the cams. Now that you realize just techy and well-planned the whole HD camera set-up is, let's talk about the people. The people that inhabit those apartments are regular real-life folks that happen to love sex and exhibitionism.</P></Link>
        {social &&
          <Social
            website={social.website}
            github={social.github}
            twitter={social.twitter}
            linkedin={social.linkedin}
          />
        }
      </Wrapper>
    </Container>
  );
}

export default Header;
