import React from 'react';

import Container from '../Container';
import Social from '../Social';
import Wrapper from './Wrapper';

function Footer({ config }) {
  const { social } = config;

  return (
    <Container>
      <Wrapper>
        {social &&
          <Social
            website={social.website}
            github={social.github}
            twitter={social.twitter}
            linkedin={social.linkedin}
          />
        }
        (c) 2024 voyeurhouse.com<br/>
        <a href={"https://voyeurhouse.com/cms/privacy"} >Policy</a><br/>
        <a href={"https://voyeurhouse.com/cms/tos"} >TOS</a>
      </Wrapper>
    </Container>
  );
}

export default Footer;
